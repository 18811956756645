import type { UseFetchOptions } from 'nuxt/app';
import type { FetchContext } from 'ofetch';
import type { Ref } from 'vue';

export function useApi<T>(
  request: string | Ref<string> | (() => string),
  options: UseFetchOptions<T> = {},
) {
  const { $baseURL } = useNuxtApp();
  const headers = useRequestHeaders(['cookie']) as HeadersInit;

  const { logout } = useAuth();

  return useFetch<T>(
    request,
    {
      ...options,
      baseURL: $baseURL,
      headers: {
        ...options?.headers,
        ...headers,
      },
      onResponseError({ response, error }: FetchContext<T>) {
        if (response?.status === 401)
          logout();

        else
          return error;
      },
    } as any,
  );
}
